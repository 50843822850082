import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import { Helmet } from "react-helmet"
import Img from "gatsby-image"
import "../styles/film-page.scss"

export default function Template({ data }) {
    const { markdownRemark } = data
    const { frontmatter, html } = markdownRemark
    var title = frontmatter.title+" | ShortOrme Productions";
    return (
        <Layout>
            <Helmet title={title} />
            <div className="film-page">
                <div id="film-header">
                  <h1>
                    {frontmatter.title}
                    {frontmatter.year !== null && <span> ({frontmatter.year})</span>}
                  </h1>
                  <Img fluid={frontmatter.featuredimage.childImageSharp.fluid} className="featuredimg" alt="Featured image" />
                </div>
                <div class="meta">
                  <p class="directedby">Directed by: {frontmatter.director}</p>
                  <p class="writtenby">Written by: {frontmatter.writer}</p>
                  {frontmatter.runtime !== null && <p class="runtime">Running time: {frontmatter.runtime}</p>} 
                </div>
                <p class="synopsis">{frontmatter.synopsis}</p>                
                {frontmatter.youtube !== null && <div class="watchfilm"><iframe title={frontmatter.title} src={`https://www.youtube.com/embed/${frontmatter.youtube}`} frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe></div>}
                <div className="film-content" dangerouslySetInnerHTML={{ __html: html }} />
            </div>
        </Layout>
    )
  }
  export const pageQuery = graphql`
    query($slug: String!) {
      markdownRemark(fields: { slug: { eq: $slug } }) {
        html
        frontmatter {
          date(formatString: "MMMM DD, YYYY")
          title
          year
          director
          writer
          synopsis
          youtube
          runtime
          featuredimage{
            childImageSharp {
              fluid(maxWidth: 500) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  `